import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
      checkout: {},
      cart: {},
      event: {},
      order: null,
      accessToken: null,
      locale: null,
      googlePayData: null,
      applePayData: null,
      paymentsConfig: null,
      /**
       * identifica se o usuario ja passou pela tela de mobile
       * e login
       */
      hasUserIdentifier: false,
      concludedCheckout: false,
      countryCode: localStorage.getItem('countryCode') || '',
      mbway_country_code: localStorage.getItem('mbway_country_code') || '',
      mbway_mobile: localStorage.getItem('mbway_mobile') || '',
    },
    actions,
    getters,
    mutations,
  };
