import { cloneDeep } from "lodash";

export default {
  RESET_CHECKOUT(state) {
    state.checkout = {};
  },
  CHECKOUT_PARAMS(state, value) {
    let props = cloneDeep(state.checkout);
    state.checkout = Object.assign(props, value);
  },
  SET_CART(state, value) {
    state.cart = value;
  },
  SET_EVENT(state, value) {
    state.event = value;
  },
  SET_ORDER(state, value) {
    state.order = value;
  },
  SET_TOKEN(state, value) {
    state.accessToken = value;
  },
  ADD_CART_PARAMS(state, value) {
    let props = cloneDeep(state.cart);
    state.cart = Object.assign(props, value);
  },
  SET_USER_IDENTIFIER(state, value) {
    state.hasUserIdentifier = value;
  },
  CONCLUDED_CHECKOUT(state, value) {
    state.concludedCheckout = value;
  },
  GOOGLE_PAY_DATA(state, value) {
    state.googlePayData = value;
  },
  PAYMENTS_CONFIG(state, value) {
    state.paymentsConfig = value;
  },
  setLocale(state, value) {
    //  state.locale = value;
  },
  APPLE_PAY_DATA(state, value) {
    state.applePayData = value;
  },

  SET_COUNTRY_CODE(state, countryCode) {
    state.countryCode = countryCode;
    localStorage.setItem('countryCode', countryCode);
  },

  SET_MBWAY_COUNTRY_CODE(state, countryCode) {
    state.mbway_country_code = countryCode;
    localStorage.setItem('mbway_country_code', countryCode);
  },
  SET_MBWAY_MOBILE(state, mobile) {
    state.mbway_mobile = mobile;
    localStorage.setItem('mbway_mobile', mobile);
  },
}
