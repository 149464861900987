import { cloneDeep } from "lodash";

export default {
  checkout: state => cloneDeep(state.checkout),
  cart: state => cloneDeep(state.cart),
  event: state => cloneDeep(state.event),
  order: state => cloneDeep(state.order),
  hasUserIdentifier: state => state.hasUserIdentifier,
  concludedCheckout: state => state.concludedCheckout,
  accessToken: state => state.accessToken,
  locale: state => (state.locale || localStorage.getItem('zicket-locale')),
  googlePayData: state => state.googlePayData,
  paymentsConfig: state => state.paymentsConfig,
  applePayData: state => state.applePayData,
  countryCode: state => state.countryCode,

  mbway_country_code: (state) => state.mbway_country_code,
  mbway_mobile: (state) => state.mbway_mobile,
}
